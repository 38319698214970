import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { DATA } from "../components/data"

const RealisationPage = ({ location }) => {
  if (!location.search) return null
  const { realisations } = DATA
  let name = location.search.replace("?", "")
  let itemIndex = realisations.findIndex(realisation => realisation.id === name)

  let previousItemIndex = itemIndex - 1
  let nextItemIndex = itemIndex + 1

  if (!realisations[itemIndex]) return null

  const data = realisations[itemIndex]

  return (
    <Layout>
      <SEO title={`Realizacja - ${data.name}`} description={`Realizacja - ${data.name} - ${data.work_description}`}/>

      <div className="realistion-bg">
        <div className="container-fluid">
          <div className="row">
            <div className="realisation-container">
              <div className="col-md-2 col-sm-2 col-xs-2">
                {itemIndex > 0 && (
                  <Link
                    to={`/realisation-page/?${realisations[previousItemIndex].id}`}
                  >
                    <div className="arrow-container">
                      <img
                        alt="Strzałka w lewo"
                        className="arrow-img"
                        src="../img/chevron-left.svg"
                      />
                    </div>
                  </Link>
                )}
              </div>

              <div className="col-md-8 col-xs-8 col-sm-8">
                <div className="row">
                  <div className="col-md-5 col-sm-12 col-xs-12">
                    <div className="realisation-text">
                      {data.logo_url && (
                        <img
                          alt={data.name}
                          className="realisation-logo-img img-responsive"
                          src={data.logo_url}
                        />
                      )}
                      <h1>{data.name}</h1>
                      <div className="line-about-lg"></div>
                      <p>
                        <strong>Lokalizacja:</strong> {data.location}
                      </p>
                      <p>
                        <strong>Rok:</strong> {data.year}
                      </p>
                      {data.quantity && (
                        <p>
                          <strong>Ilość:</strong> {data.quantity}
                        </p>
                      )}
                      <p>
                        <strong>Zakres prac:</strong>
                      </p>
                      <p>{data.work_description}</p>
                    </div>
                  </div>
                  <div className="col-md-1 col-sm-12 col-xs-12">
                    <div className="line-leaning-separator"></div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 realisation-img-container">
                    <div>
                      <div>
                        <img
                          alt={data.location}
                          src={data.preview_url}
                          className="realisation-img polygon-clip-rhomboid"
                        />
                      </div>
                      <svg className="clip-path">
                        <defs>
                          <clipPath
                            id="polygon-clip-rhomboid"
                            clipPathUnits="objectBoundingBox"
                          >
                            <polygon points="0 1, 0 0, 1 0, 1 1" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-2 col-xs-2 col-sm-2">
                {itemIndex < realisations.length - 1 && (
                  <Link
                    to={`/realisation-page/?${realisations[nextItemIndex].id}`}
                  >
                    <div className="arrow-container">
                      <img
                        className="arrow-img"
                        alt="Strzałka w prawo"
                        src="../img/chevron-right.svg"
                      />
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RealisationPage
